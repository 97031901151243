import { Circle, PlayCircle } from "@mui/icons-material";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ThumbnailImage = styled("img")(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    height: "auto",
    width: "120%",
    clipPath: "inset(0 19% 0 0 round 20px)",
    marginLeft: "1.5%",
  },
  [theme.breakpoints.up("sm")]: {
    width: 500,
    height: 300,
    objectFit: "cover",
    objectPosition: "left",
  },
  [theme.breakpoints.up("lg")]: {
    width: 800,
    height: 500,
  },
  borderRadius: 20,
  cursor: "pointer",
}));

export const PlayerWrapper = styled("figure")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
}));

const playButtonSizeSm = 60;
const playButtonSizeLg = 120;

export const PlayCircleIcon = styled(PlayCircle)({
  width: "100%",
  height: "auto",
  zIndex: 2,
  position: "absolute",
  boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.28)",
  borderRadius: 80,
});

export const PlayCircleBacking = styled(Circle)({
  color: "white",
  width: "90%",
  height: "auto",
  zIndex: 1,
});

export const PlayButtonBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    marginLeft: "-1.5%",
    height: "auto",
    width: "25%",
    alignItems: "center",
  },
  [theme.breakpoints.up("sm")]: {
    marginLeft: -15,
    height: playButtonSizeSm,
    width: playButtonSizeSm,
  },
  [theme.breakpoints.up("lg")]: {
    marginLeft: -45,
    height: playButtonSizeLg,
    width: playButtonSizeLg,
  },
  position: "absolute",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
}));
