export enum AccentColors {
  primary = "primary",
  secondary = "secondary",
}

type CustomPalette = {
  primary: { main: string };
  secondary: { main: string };
};

const palette: CustomPalette = {
  primary: {
    main: "#008fc1",
  },
  secondary: {
    main: "#203458",
  },
};

export default palette;
