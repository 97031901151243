import {
  LightboxConfiguration,
  LightboxPlayer,
} from "@money-alive/video-canvas";
import { useEffect, useState } from "react";
import palette, { AccentColors } from "theme/palette";
import * as S from "./VideoCanvas.styled";

type VideoCanvasProps = Omit<LightboxConfiguration, "origin" | "branding">;

type VideoPlayerProps = VideoCanvasProps & {
  accentColor: AccentColors;
};

export const getVcOrigin = (): string => {
  return `${window.location.protocol}//${window.location.host}`;
};

const getThumbnailImageData = async (vcId: string): Promise<string> => {
  const videoMetadataJson = await (
    await fetch(`${getVcOrigin()}/vc/${vcId}/data.json`)
  ).json();
  return videoMetadataJson.slides[0].variants.a.video.thumbnail;
};

const VideoPlayer = ({ id: vcId, content, accentColor }: VideoPlayerProps) => {
  const [player, setPlayer] = useState<LightboxPlayer | null>(null);
  const [thumbnailData, setThumbnailData] = useState<string>("");

  useEffect(() => {
    const fetchImageData = async () => {
      const imageData = await getThumbnailImageData(vcId);
      setThumbnailData(imageData);
    };
    fetchImageData();
  }, [vcId]);

  useEffect(() => {
    try {
      const newPlayer = new LightboxPlayer({
        id: vcId,
        content,
        branding: {
          brandColor: palette[accentColor].main,
          playButtonColor: palette[accentColor].main,
          ctaButtonColor: palette[accentColor].main,
        },
      });

      setPlayer(newPlayer);
    } catch (error) {
      // Issue finding initFonts when creating the lightbox player, so need to wrap in a try-catch
    }
  }, [vcId, content, accentColor]);

  const handleOpenVideo = () => {
    player?.show();
  };

  // Video just reopens itself without this
  if (player) {
    player.on("quit-player", () => {
      player.hide();
    });
  }

  return (
    <S.PlayerWrapper onClick={handleOpenVideo}>
      <S.PlayButtonBox>
        <S.PlayCircleIcon viewBox={"2 2 20 20"} color={accentColor} />
        <S.PlayCircleBacking viewBox={"2 2 20 20"} />
      </S.PlayButtonBox>

      <S.ThumbnailImage src={thumbnailData} />
    </S.PlayerWrapper>
  );
};

export default VideoPlayer;
