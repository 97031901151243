const EXPECTED_FORENAME = "Jeremy";
const EXPECTED_PENSION_YEAR = "2024";
const EXPECTED_ANNUAL_PENSION = 30_000;
const EXPECTED_ANNUAL_PENSION_AFTER_MAX_LUMP_SUM = 20_000;
const EXPECTED_ANNUAL_SURVIVORS_PENSION = 10_000;
const EXPECTED_DEATH_GRANT_LUMP_SUM = 35_000;
const EXPECTED_TAX_FREE_LUMP_SUM = 55_000;
const EXPECTED_TAX_FREE_LUMP_SUM_AFTER_MAXIMUM_LUMP_SUM = 95_000;
const EXPECTED_PRESENT_ANNUAL_PENSION = 30_000;
const EXPECTED_PRESENT_LUMP_SUM = 40_000;

export const videoData = {
  vA: `${EXPECTED_FORENAME}'s`,
  vB: EXPECTED_PENSION_YEAR,
  vC: EXPECTED_PRESENT_ANNUAL_PENSION.toFixed(2).toString(),
  vD: EXPECTED_PRESENT_LUMP_SUM.toFixed(2).toString(),
  vE: EXPECTED_ANNUAL_PENSION.toFixed(2).toString(),
  vF: (EXPECTED_ANNUAL_PENSION / 12).toFixed(2).toString(),
  vG: EXPECTED_TAX_FREE_LUMP_SUM.toFixed(2).toString(),
  vH: EXPECTED_TAX_FREE_LUMP_SUM_AFTER_MAXIMUM_LUMP_SUM.toFixed(2).toString(),
  vI: EXPECTED_ANNUAL_PENSION_AFTER_MAX_LUMP_SUM.toFixed(2).toString(),
  vJ: (EXPECTED_ANNUAL_PENSION_AFTER_MAX_LUMP_SUM / 12).toFixed(2).toString(),
  vK: EXPECTED_DEATH_GRANT_LUMP_SUM.toFixed(2).toString(),
  vL: EXPECTED_ANNUAL_SURVIVORS_PENSION.toFixed(2).toString(),
  vM: EXPECTED_PENSION_YEAR,
  vN: "",
};
