import { Button, Grid, Typography } from "@mui/material";
import { videoData } from "features/video/videoData";
import VideoPlayer from "features/video/VideoPlayer";
import React from "react";
import { AccentColors } from "theme/palette";
import "./App.css";

type Video = {
  id: string;
  title: string;
  color: AccentColors;
};

const Videos = {
  activeAbs: {
    id: "clgm94g2p00010zqvm13o599j",
    title: "Active ABS",
    color: AccentColors.primary,
  },
  deferredAbs: {
    id: "cltwtbf4i0001qoqy4shmlm21",
    title: "Deferred ABS",
    color: AccentColors.secondary,
  },
};

const getOtherVideo = (currentVideo: Video) =>
  currentVideo.id === Videos.activeAbs.id
    ? Videos.deferredAbs
    : Videos.activeAbs;

function App() {
  const initialVideo = localStorage.getItem("currentVideo");
  let currentVideo: Video;
  if (initialVideo) {
    currentVideo = JSON.parse(initialVideo);
  } else {
    currentVideo = Videos.activeAbs;
  }

  const handleVideoChange = () => {
    localStorage.setItem(
      "currentVideo",
      JSON.stringify(getOtherVideo(currentVideo)),
    );

    // Refresh the page so the video canvas fully reloads
    window.location.reload();
  };

  return (
    <div className="App">
      <div className="Container">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <img src={"/heywood_logo.png"} alt={"Heywood Logo"} width={"50%"} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant={"h1"}>Video ABS Demo</Typography>
            <Typography variant={"h2"}>{currentVideo.title}</Typography>
          </Grid>
        </Grid>
        <VideoPlayer
          id={currentVideo.id}
          content={videoData}
          accentColor={currentVideo.color}
        />
        <Button
          onClick={handleVideoChange}
          variant={"contained"}
          color={currentVideo.color}
        >
          <Typography variant={"body1"}>
            Switch to {getOtherVideo(currentVideo).title}
          </Typography>
        </Button>
      </div>
    </div>
  );
}

export default App;
